import { Injectable } from '@angular/core';
import {Apollo, TypedDocumentNode} from "apollo-angular";

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {

  protected constructor(protected apollo: Apollo) { }

  fetchData(query: TypedDocumentNode<unknown, unknown>, local: string = 'en') {
    return this.apollo
      .watchQuery({
        query,
        variables: {
          local: local
        }
      });
  }
  fetchFilterData(query: TypedDocumentNode<unknown, unknown>, variables: any) {
    return this.apollo
      .watchQuery({
        query,
        variables
      });
  }
}
