export const environment = {
  production: true,
  api: {
    url: 'https://cms-test-img.bt24.app',
    token: "Bearer f75e9de93fc6559d619ef23f0c3e4945f2407efae914f6c0da530689b59f694e2152725b0339ae95f014a1610f784ea30b7656a52b9189c3aa245d0cb3b5a8ef97b70006baeb04e8eb9fb08600272fb514f0aee2f06907786eeb57b960eb2ff0a4e77ca766f227187a194545ef7f31b2393396338a5f996b82d8bb9cd467a554"
  },
  server: {
    url: 'http://ec2-3-11-68-250.eu-west-2.compute.amazonaws.com'
  },
  media: {
    url: 'https://cms-test-img.bt24.app'
  },
  language: 'en',
  title: 'Beauty Travels 24',
};
