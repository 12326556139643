import {Injectable} from '@angular/core';
import {Apollo} from "apollo-angular";
import {BaseService} from "./base.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends BaseService{
  language: string = environment.language;
  constructor(
    protected override apollo: Apollo) {
    super(apollo);
  }

  setLanguage(local: string = 'en') {
    try {
      localStorage.setItem('lang',local||environment.language);
    } catch (e) {
      try {
        sessionStorage.setItem('lang', local || environment.language);
      } catch (e) {
        this.language = local || environment.language;
      }
    }
    this.language = local || environment.language;
  }
  getLanguage() {
    try {
      this.language = localStorage.getItem('lang') || environment.language;
    }catch (e) {
      try {
        this.language = sessionStorage.getItem('lang') || environment.language;
      } catch (e) {
        this.language = environment.language;
      }
    }
    return this.language;
  }
}
